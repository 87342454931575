import { useState } from "react";
import { Col, Row, Drawer, Image } from "antd";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/icon4.png";
import icon5 from "../../assets/icon5.png";
import icon6 from "../../assets/icon6.png";
import icon7 from "../../assets/icon7.png";
import icon8 from "../../assets/icon8.png";
import icon9 from "../../assets/icon9.png";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { MdMenu } from "react-icons/md";

const SideMenu = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  return (
    <>
      <Row>
        <Col xs={0} md={0} lg={0} xl={24}>
          <div className="side-menu">
            {/* ... other menu items ... */}
            <div
              className={`vector-one-parent ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={icon1}
                className="vector-one-icon"
              />
              <div
                className="my-profile"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                Dashboard
              </div>
            </div>
            <div
              className={`vector-one-parent ${
                location.pathname.includes("/roomListing") ||
                location.pathname.includes("/roomDetails") ||
                location.pathname.includes("/editRoom") || 
                location.pathname.includes("/addRoom")  
                  ? "active"
                  : ""
              }`}
            >
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={icon2}
                className="vector-one-icon"
              />
              <div
                className="my-profile"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/roomListing")}
              >
                Room Listing
              </div>
            </div>
            <div
              className={`vector-one-parent ${
                location.pathname.includes("/reservationLogs") ||
                location.pathname.includes("/reservationLogsDetails")  
                  ? "active"
                  : ""
              }`}
            >
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={icon2}
                className="vector-one-icon"
              />
              <div
                className="my-profile"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/reservationLogs")}
              >
                Room Reservation Logs
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} md={24} lg={24} xl={0}>
          <>
            <MdMenu
              style={{ fontSize: 26, color: "#000" }}
              onClick={showDrawer}
            />
            <Drawer
              // title="Basic Drawer"
              placement="right"
              onClose={onClose}
              open={open}
            >
              <div className="side-menu">
                <div className="bg2" />
                <div className="vector-one-parent">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={icon1}
                    className="vector-one-icon"
                  />
                  <div
                    className="my-profile"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/")}
                  >
                    Dashboard
                  </div>
                </div>
                <div className="reservation-logs-text">
                  <div className="gift-cards-text-parent">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={icon2}
                      className="vector-one-icon"
                    />
                    <div
                      className="jump-reservation-logs"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/dropZoneListing")}
                    >
                      Drop Zone Listing
                    </div>
                  </div>
                  <div className="location-text-parent">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={icon3}
                      className="vector-one-icon"
                    />
                    <div
                      className="gift-cards"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/paymentLogs")}
                    >
                      Payment Logs
                    </div>
                  </div>
                </div>
                <div className="edit-profile-change-password-g-parent">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={icon4}
                    className="vector-one-icon"
                  />
                  <div
                    className="my-orders"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/dropZoneLogs")}
                  >
                    Drop Zone Logs
                  </div>
                </div>
                <div className="edit-profile-change-password-g-parent">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={icon7}
                    className="vector-one-icon"
                  />
                  <div
                    className="my-orders"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/careers")}
                  >
                    Careers
                  </div>
                </div>
                <div className="edit-profile-change-password-g-parent">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={icon8}
                    className="vector-one-icon"
                  />
                  <div
                    className="my-orders"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/pricingManagement")}
                  >
                    Pricing Management
                  </div>
                </div>
                <div className="edit-profile-change-password-g-parent">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={icon5}
                    className="vector-one-icon"
                  />
                  <div
                    className="my-orders"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/businessHoursManagement")}
                  >
                    Business Hours Management
                  </div>
                </div>
                <div className="edit-profile-change-password-g-parent">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={icon6}
                    className="vector-one-icon"
                  />
                  <div
                    className="my-orders"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/upcomingEvents")}
                  >
                    Upcoming Events
                  </div>
                </div>
                <div className="edit-profile-change-password-g-parent">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={icon9}
                    className="vector-one-icon"
                  />
                  <div
                    className="my-orders"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/instructorsRiggers")}
                  >
                    Instructors & Riggers
                  </div>
                </div>
              </div>
            </Drawer>
          </>
        </Col>
      </Row>
    </>
  );
};

export default SideMenu;
