import { Col, Row, Image, Form, Input, Button, theme } from "antd";
import { useNavigate } from "react-router";
import { FaArrowLeftLong, FaPhoneVolume, FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import fb from "../../assets/fb.png";
import inst from "../../assets/inst.png";
import snap from "../../assets/snap.png";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { Post } from "../../config/api/post";
import { FEEDBACK } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { useState } from "react";


const Help = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading , setLoading] = useState(false)
  const userToken = useSelector((state) => state.user.userToken);
  const onFinish = (values) => {
    // Handle form submission
    setLoading(true);
    const { firstName, lastName, email, message, subject } = values;
    let body = {
      firstName,
      lastName,
      email,
      message,
      subject,
      type : 'STAY'
    };
    Post(FEEDBACK.addFeedback, body , userToken ).then((response)=>{
      if(response.status){
      setLoading(false);
      form.resetFields()
      handleChange()
      }
    }).catch((err)=>{
      let message = err?.response?.data?.message;
        setLoading(false);
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
    })
  };
  const { TextArea } = Input;
  const handleChange = () => {
    swal("System Alert", "Your Response has been Recorded!", "success");
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Help & Support</h3>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <Row justify="center">
                <Col xl={18} lg={20}>
                  <div className="details-card my-4">
                    <Row gutter={[16, 16]}>
                      <Col xl={9} lg={10}>
                        <div className="help-frame">
                          <div>
                            <h6>Contact Information</h6>
                            <span>ABC Company Name</span>
                          </div>
                          <div>
                            <p>
                              <FaPhoneVolume /> email@email.com
                            </p>
                            <p>
                              <MdEmail /> demo@gmail.com
                            </p>
                            <p>
                              <FaLocationDot /> 132 Dartmouth Street, Boston,
                              Massachusetts 02156, United States
                            </p>
                          </div>
                          <div className="social-icons">
                            <Link to={""}>
                              <Image
                                preview={false}
                                alt={"Failed to load image"}
                                src={fb}
                                className=""
                              />
                            </Link>
                            <Link to={""}>
                              <Image
                                preview={false}
                                alt={"Failed to load image"}
                                src={inst}
                                className=""
                              />
                            </Link>
                            <Link to={""}>
                              <Image
                                preview={false}
                                alt={"Failed to load image"}
                                src={snap}
                                className=""
                              />
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col xl={15} lg={14}>
                        <Form
                          className="row g-3"
                          name="basic"
                          layout="vertical"
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                          autoComplete="off"
                          form={form}
                        >
                          <Row style={{ width: "100%" }} gutter={[16, 16]}>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your First Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter First Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Last Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Last Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Email!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Email address"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Subject"
                                name="subject"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Subject!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Subject"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Message"
                                name="message"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Message!",
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Write your message.."
                                  // maxLength={100}
                                  className="web-textarea"
                                />
                              </Form.Item>
                            </Col>
                            <div className="" style={{ textAlign: "center" }}>
                              <Button
                                type=""
                                htmlType="submit"
                                className="btn web-btn px-5"
                              >
                               {loading ? "Loading..." : "Send Feedback"}
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Help;
