import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Upload, Button, Image, Checkbox } from "antd";
import { useNavigate } from "react-router";
import { FaArrowLeftLong, FaSnowflake, FaWifi, FaSpa } from "react-icons/fa6";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  MdAirportShuttle,
  MdFitnessCenter,
  MdOutlineRestaurantMenu,
} from "react-icons/md";
import { FaParking, FaSwimmingPool } from "react-icons/fa";
import swal from "sweetalert";
import paymentImg from "../../assets/payment-img.png";
import { Post } from "../../config/api/post";
import { AMENITY, STAY } from "../../config/constants/api";
import { useDispatch, useSelector } from "react-redux";
import { addProfileDetails } from "../../redux/slice/authSlice";
import { Get } from "../../config/api/get";
import { ImSpoonKnife } from "react-icons/im";
import { Tb24Hours } from "react-icons/tb";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const normFile = (e) => {
  if (Array.isArray(e)) {
    console.log(e);
    return e;
  }
  return e?.fileList;
};

const DropzoneCompany = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [checkedArray, setCheckedArray] = useState([]);
  const [stayAmenities, setStayAmenities] = useState(null);
  const authToken = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (profileDetails) {
      navigate("/stayProfileView");
    }
  }, []);
  const getStayAmenities = () => {
    Get(AMENITY.getAmenities, authToken, { type: "STAY" })
      .then((response) => {
        if (response.status) {
          setStayAmenities(response?.data?.docs);
        }
      })
      .catch((err) => {
        console.log(err, " Error fetching stay Amenities");
      });
  };
  useEffect(() => {
    getStayAmenities();
  }, []);
  const handleUploadChange = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const onFinish = (values) => {
    if (
      checkedArray.length < 1 ||
      fileList.length < 1 ||
      longitude === "" ||
      latitude === ""
    ) {
      swal("Error!", "Please Provide complete Information", "error");
    } else {
      const location = {
        coordinates: [longitude, latitude],
        address: values.address,
        apartment: values.apartment,
        street: values.street,
        state: values.state,
        city: values.city,
        country: values.country,
        zipCode: values.zipCode,
      };
      const accountDetails = {
        name: values.accountName,
        country: values.accountCountry,
        city: values.accountCity,
        iban: values.iban,
        accNumber: values.accNumber,
        swift: values.swift,
        bankName: values.bankName,
        branchName: values.branchName,
        bankAddress: values.bankAddress,
        currency: values.accountCurrency,
      };

      let data = new FormData();
      if (fileList.length > 1) {
        const fileListToAppend = fileList.slice(1).map((file) => {
          return file.originFileObj;
        });
        fileListToAppend.forEach((val) => {
          data.append("gallery", val);
        });
      }
      if (fileList[0]) {
        data.append("image", fileList[0].originFileObj);
      }
      checkedArray.forEach((val) => {
        const foundAmenity = stayAmenities.find((amenity) => {
          return amenity.title === val;
        });
        if (foundAmenity) {
          data.append("amenities", foundAmenity._id);
        }
      });
      data.append("title", values.title);
      data.append("description", values.description);
      data.append("location", JSON.stringify(location));
      data.append("accountDetails", JSON.stringify(accountDetails));
      Post(STAY.createProfile, data, authToken, null, "multipart")
        .then((response) => {
          if (response.status) {
            const selectedAmenityObjects = checkedArray.map((title) =>
              stayAmenities.find((amenity) => amenity.title === title)
            );

            dispatch(
              addProfileDetails({
                details: {
                  ...response?.data,
                  amenities: selectedAmenityObjects,
                },
              })
            );
            form.resetFields();
            swal("System Alert", "Profile Created Successfully", "success");
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          const message = err?.response?.data?.message;
          if (message) {
            swal("Oops", message, "error");
          }
        });
    }
  };
  const { TextArea } = Input;

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };
  const handleSelect = (newAddress) => {
    setAddress(newAddress);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLongitude(latLng?.lng);
        setLatitude(latLng?.lat);
      })
      .catch((error) => console.error("Error", error));
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Image (Recommended PNG, JPG upto 2MB)
      </div>
    </button>
  );

  const amenityOptions = [
    {
      label: "Breakfast",
      value: "Breakfast",
      image: <ImSpoonKnife />,
    },

    {
      label: "Air Conditioning/Heating",
      value: "Air Conditioning/Heating",
      image: <FaSnowflake />,
    },
    {
      label: "Free Wi-Fi",
      value: "Free Wi-Fi",
      image: <FaWifi />,
    },
    {
      label: "Parking",
      value: "Parking",
      image: <FaParking />,
    },
    {
      label: "Airport Shuttle",
      value: "Airport Shuttle",
      image: <MdAirportShuttle />,
    },
    {
      label: "Swimming Pool",
      value: "Swimming Pool",
      image: <FaSwimmingPool />,
    },
    {
      label: "Fitness Center",
      value: "Fitness Center",
      image: <MdFitnessCenter />,
    },
    {
      label: "Spa and Wellness",
      value: "Spa and Wellness",
      image: <FaSpa />,
    },
    {
      label: "Restaurant",
      value: "Restaurant",
      image: <MdOutlineRestaurantMenu />,
    },
    {
      label: "24-Hour Front Desk",
      value: "24-Hour Front Desk",
      image: <Tb24Hours />,
    },
  ];
  const onAmenityChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setCheckedArray(checkedValues);
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Create Stay Profile</h3>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <Row justify="center">
                <Col xl={24} lg={20}>
                  <div className="details-card my-4">
                    <Form
                      className="row g-3"
                      name="basic"
                      layout="vertical"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      autoComplete="off"
                      form={form}
                    >
                      <Row style={{ width: "100%" }} gutter={[16, 16]}>
                        <Col lg={12} md={12} xs={24}>
                          <Row style={{ width: "100%" }} gutter={[16, 16]}>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                              >
                                <Upload
                                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                  listType="picture-card"
                                  fileList={fileList}
                                  onChange={handleUploadChange}
                                >
                                  {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={[16, 16]}>
                            <Col lg={24}>
                              <h5 className="heading-18">ADD AMENITIES</h5>
                              <div className="offer-tabs">
                                <Checkbox.Group
                                  options={amenityOptions.map((option) => ({
                                    label: (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                        className="check-icon"
                                      >
                                        {option.image}
                                        {option.label}
                                      </div>
                                    ),
                                    value: option.value,
                                    style: {
                                      backgroundColor: checkedArray.includes(
                                        option.value
                                      )
                                        ? "#0f75bd"
                                        : "",
                                    },
                                  }))}
                                  onChange={onAmenityChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={12} md={12} xs={24}>
                          <Row style={{ width: "100%" }} gutter={[16, 16]}>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Stay Title"
                                name="title"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Stay Title!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Stay Title Here"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Description!",
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Bring the whole family to this great place with lots of room for fun."
                                  maxLength={6}
                                  className="web-textarea"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <h3>Enter Location</h3>
                              <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => {
                                  return (
                                    <div className="location-drop">
                                      <input
                                        {...getInputProps({
                                          placeholder:
                                            "Search location here...",
                                          className: "location-search-input",
                                          style: {
                                            position: "relative",
                                            width: "100%",
                                            marginBottom: "10px",
                                            height: "52px",
                                            borderRadius: "6px",
                                            border: "1px solid #E3E3E3",
                                            backgroundColor: "#F8F8F8",
                                            padding: "10px 50px 10px 10px",
                                          },
                                        })}
                                      />
                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active googleSuggestion"
                                            : "suggestion-item googleSuggestion";
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                                padding: "5px 10px",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                                padding: "5px 10px",
                                              };
                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  );
                                }}
                              </PlacesAutocomplete>
                              <Form.Item
                                label="Address"
                                name="address"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Address!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Address"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Street"
                                name="street"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Street!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Street"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Apartment"
                                name="apartment"
                                rules={[
                                  {
                                    message: "Please enter your Apartment!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Apartment #, Suite, etc. (Optional)"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="City"
                                name="city"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your City!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Select City"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="State"
                                name="state"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your State!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Select State"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Zip Code"
                                name="zipCode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Zip Code!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Zip Code"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Country"
                                name="country"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Country!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Select Country"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row style={{ width: "100%" }} gutter={[16, 16]}>
                        <Col lg={10} md={10} xs={24}>
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={paymentImg}
                            className=""
                          />
                        </Col>
                        <Col lg={14} md={14} xs={24}>
                          <h2 className="booking-card-price">
                            Bank Account Details
                          </h2>
                          <Row style={{ width: "100%" }} gutter={[16, 16]}>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Beneficiary Name"
                                name="accountName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Beneficiary Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Beneficiary Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Remittance Country"
                                name="accountCountry"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter Remittance Country*!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Country"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Account Currency"
                                name="accountCurrency"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Account Currency!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="$"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="IBAN"
                                name="iban"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter IBAN!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter IBAN"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Account Number"
                                name="accNumber"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Account Number!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Account Number"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Bank Name"
                                name="bankName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Bank Name!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Bank Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="SWIFT Code/Routing Number"
                                name="swift"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter SWIFT Code/Routing Number*!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Swift Code/Routing Number"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Branch Name"
                                name="branchName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Branch Name*!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Branch Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="City"
                                name="accountCity"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter City!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter City"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Bank Address"
                                name="bankAddress"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Bank Address!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Bank Address"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <div className="">
                                <Button
                                  type="submit"
                                  htmlType="submit"
                                  // className={
                                  //   !gallery.length > 0
                                  //     ? "web-btn3"
                                  //     : "web-btn"
                                  // }
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="web-btn"
                                >
                                  Save Now!
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneCompany;
