import { useEffect, useState } from "react";
import { Col, Row, Button } from "antd";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { STAY, UPLOADS_URL } from "../../../config/constants/api";
import DashbordSidebar from "../../../components/DashboardSidebar";
import { Get } from "../../../config/api/get";

const DropzoneFiltercards = () => {
  const [accountDetails, setAccountDetails] = useState(null);
  const token = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const navigate = useNavigate();
  const getMyAccount = () => {
    Get(STAY.getMyAccount, token)
      .then((response) => {
        if (response.status) {
          setAccountDetails(response?.data);
        }
      })
      .catch((err) => {
        console.log("Error fetching Profile Information ", err);
      });
  };
  useEffect(() => {
    getMyAccount();
  }, []);
  

  return (
    <div className="shop-page">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={21}>
            <div className="shop-page-main">
              <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24}>
                  <div className="my-account-profile">
                    <section className="side-menu-parent">
                      <DashbordSidebar />
                      <div className="about-us-section">
                        {accountDetails && (
                          <div className="bg-parent">
                            <Row align={"middle"} style={{marginBottom:"15px"}}>
                              <Col lg={14}>
                                <h3 className="heading-28">
                                  Profile Information
                                </h3>
                              </Col>
                              <Col lg={10} style={{textAlign:'end'}}>
                                <Button
                                  type=""
                                  size={"large"}
                                  style={{ color:"red" }}
                                  className=""
                                  onClick={() =>
                                    navigate("/changePassword", {
                                      state: {
                                        email: accountDetails?.email,
                                      },
                                    })
                                  }
                                >
                                  Change password
                                </Button>
                              </Col>
                            </Row>
                            <Row
                              gutter={[16, 16]}
                              align={"middle"}
                              justify={"space-between"}
                            >
                              <Col md={10} lg={10} xl={8}>
                                <div className="wrapper-group-1000001858">
                                  <img
                                    src={UPLOADS_URL + profileDetails?.image}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </Col>
                              <Col md={14} lg={14} xl={14}>
                                <div className="">
                                  <div className="logo-rectangle">
                                    <div className="profile-info">
                                      <div className="full-name">
                                        <div className="jake-dawson">
                                          <div className="phone-number">
                                            <div className="full-name1">
                                              <p className="full-name2">
                                                First Name
                                              </p>
                                            </div>
                                            <div className="jake-dawson1">
                                              {accountDetails?.firstName}
                                            </div>
                                          </div>

                                          <div className="gender">
                                            <div className="phone-number1">
                                              Phone Number
                                            </div>
                                            <div className="frame-parent">
                                              <div className="a-b-c">
                                                {accountDetails?.mobile}
                                              </div>
                                            </div>
                                          </div>
                                          {profileDetails?.location &&<div className="location">
                                            Location
                                          </div>}
                                        </div>
                                        <div className="changepassword">
                                          <div className="b-g">
                                            <div className="email">
                                              Last Name
                                            </div>
                                            <div className="jakesamplecom">
                                              {accountDetails?.lastName}
                                            </div>
                                          </div>
                                          <div className="b-g1">
                                            <div className="gender1">
                                              Gender
                                            </div>
                                            <div className="male">
                                              {accountDetails?.gender}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {profileDetails?.location && <div className="abc-location-town">
                                        {profileDetails?.location?.street +
                                          ", " +
                                          profileDetails?.location?.state +
                                          ", " +
                                          profileDetails?.location?.city +
                                          ", " +
                                          profileDetails?.location?.country}
                                      </div>}
                                    </div>
                                    <div className="f-a-qs">
                                      <div className="career">
                                        <div className="date-of-birth">
                                          Email
                                        </div>
                                      </div>
                                      <div className="termsconditions">
                                        <div className="jan-28-1998">
                                          {accountDetails?.email}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="frame-group">
                                      <div className="">
                                        <Button
                                          type=""
                                          block
                                          size={"large"}
                                          style={{ marginBottom: "10px" }}
                                          className="web-btn"
                                          onClick={() =>
                                            navigate("/editProfile", {
                                              state: { accountDetails },
                                            })
                                          }
                                        >
                                          Edit Profile
                                        </Button>
                                      </div>
                                      <div className="">
                                        <Button
                                          type=""
                                          block
                                          size={"large"}
                                          style={{ marginBottom: "10px" }}
                                          className="web-btn2"
                                          onClick={() =>
                                            navigate("/stayProfileView"
                                            )
                                          }
                                        >
                                          View Stays Profile
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
   
    </div>
  );
};

export default DropzoneFiltercards;
