import { useState, useEffect } from "react";
import { Col, Row, Button, Input, Form, Select } from "antd";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DashbordSidebar from "../../../components/DashboardSidebar";
import "react-phone-number-input/style.css";
import swal from "sweetalert";
import { STAY, UPLOADS_URL } from "../../../config/constants/api";
import { Put } from "../../../config/api/put";

const DropzoneFiltercards = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [initialData, setInitialData] = useState({});
  const token = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const accountDetails = location?.state?.accountDetails;
  useEffect(() => {
    if (accountDetails) {
      setInitialData({
        firstName: accountDetails?.firstName,
        lastName: accountDetails?.lastName,
        gender: accountDetails?.gender,
        phone: accountDetails?.mobile,
      });
      form.setFieldsValue({
        firstName: accountDetails?.firstName,
        lastName: accountDetails?.lastName,
        email: accountDetails?.email,
        gender: accountDetails?.gender,
        phone: accountDetails?.mobile,
      });
    }
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profileDetails) {
      navigate("/createStayProfile");
    }
  }, []);
  const navigate = useNavigate();

  const onFinish = (values) => {
    const { firstName, lastName, gender, phone } = values;
    if (
      firstName === initialData?.firstName &&
      lastName === initialData?.lastName &&
      gender === initialData?.gender &&
      phone === initialData?.phone
    ) {
      swal("System Alert!", "No changes detected", "error");
      return;
    } else {
      let data = new FormData();
      if (firstName) {
        data.append("firstName", firstName);
      }
      if (lastName) {
        data.append("lastName", lastName);
      }
      if (phone) {
        data.append("mobile", phone);
      }
      if (gender) {
        data.append("gender", gender);
      }
      Put(STAY.updateAccount, token, data)
        .then((response) => {
          if (response.status) {
            swal("System Alert", "Account Updated Successfully", "success");
            navigate("/profile");
          }
        })
        .catch((err) => {
          console.log(err);
          const message = err?.response?.data?.message;
          if (message) {
            swal("Oops!", message, "error");
          }
        });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="profile-information-wrapper">
                        <h3 className="main-heading">Edit Profile</h3>
                      </div>
                      <div className="bg-parent">
                        <Row
                          gutter={[16, 16]}
                          align={""}
                          justify={"space-between"}
                        >
                          <Col md={10} lg={10} xl={8}>
                            <div className="wrapper-group-1000001858">
                              <img
                                src={UPLOADS_URL + profileDetails?.image}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </Col>

                          <Col md={14} lg={14} xl={16}>
                            <div className="">
                              <div className="logo-rectangle">
                                <div className="edit-profile-info">
                                  <Form
                                    className="row g-3"
                                    name="basic"
                                    layout="vertical"
                                    initialValues={{
                                      remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={form}
                                  >
                                    <Row
                                      style={{ width: "100%" }}
                                      gutter={[16, 16]}
                                    >
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="First Name"
                                          name="firstName"
                                          rules={[
                                            {
                                              // required: true,
                                              message:
                                                "Please enter your FIrst Name!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Last Name"
                                          name="lastName"
                                          rules={[
                                            {
                                              // required: true,
                                              message:
                                                "Please enter your Last Name!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Email"
                                          name="email"
                                          rules={[
                                            {
                                              required: false,
                                              message:
                                                "Please enter your Email!",
                                            },
                                          ]}
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                            disabled
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Phone Number"
                                          name="phone"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                            type="number"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Gender"
                                          name="gender"
                                          rules={[
                                            {
                                              // required: true,
                                              message: "Please select Gender",
                                            },
                                          ]}
                                        >
                                          <Select placeholder="Select">
                                            <Select.Option value="MALE">
                                              Male
                                            </Select.Option>
                                            <Select.Option value="FEMALE">
                                              Female
                                            </Select.Option>
                                            <Select.Option value="OTHERS">
                                              Others
                                            </Select.Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={24} md={24} xs={24}>
                                        <div className="">
                                          <Button
                                            type=""
                                            htmlType="submit"
                                            className="btn web-btn px-5"
                                          >
                                            Update
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
