import { useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import { notifications } from "../../components/Data/data.js";
import NotificationCard from "../../components/NotificationCard";
import DashbordSidebar from "../../components/DashboardSidebar";
// import "./style.css";

function Notifications() {
  const [notificationState, setNotificationState] = useState([]);

  useEffect(() => {
    setNotificationState(notifications);
  }, []);

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren dashboard-right-card">
                        <Card className="details-card for-tag-input">
                          <h3 className="heading-28">Notification</h3>
                          <Row
                            align="middle"
                            justify="space-between"
                            gutter={16}
                          >
                            <Col lg={24}>
                              {notificationState.map((notification, index) => (
                                <>
                                  <div className="col-12" key={index}>
                                    <NotificationCard
                                      text={notification.text}
                                      date={notification.date}
                                      time={notification.time}
                                      read={notification.read}
                                    />
                                  </div>
                                </>
                              ))}
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Notifications;
