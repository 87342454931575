import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  Upload,
  Form,
  Checkbox,
  DatePicker,
  Button,
  Radio,
  Space,
  Menu,
} from "antd";
import { useNavigate } from "react-router";
import DashbordSidebar from "../../components/DashboardSidebar";
import { FaArrowLeftLong, FaSnowflake, FaWifi, FaSpa } from "react-icons/fa6";
import {
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  MdAirportShuttle,
  MdFitnessCenter,
  MdOutlineRestaurantMenu,
} from "react-icons/md";
import { FaParking, FaSwimmingPool } from "react-icons/fa";
import moment from "moment";
import { Get } from "../../config/api/get";
import { AMENITY, STAY } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { disabledDate } from "../../config/helpers";
import { ImSpoonKnife } from "react-icons/im";
import { Tb24Hours } from "react-icons/tb";

const { TextArea } = Input;


const normFile = (e) => {
  if (Array.isArray(e)) {
    console.log(e);
    return e;
  }
  return e?.fileList;
};

const AddDropZone = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [amenities, setAmenities] = useState(null);
  const [dates, setDates] = useState([null]);
  const [checkedArray, setCheckedArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [guests, setGuests] = useState(1);
  const [beds, setBeds] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const onFinish = (values) => {
    if (
      checkedArray.length < 1 ||
      dates.length < 1 ||
      dates[0] === null ||
      fileList.length < 1
    ) {
      swal("Error!", "Please Provide complete Information", "error");
    } else {
      let data = new FormData();
      data.append("title", values?.title);
      data.append("description", values?.description);
      data.append("guests", guests);
      data.append("beds", beds);
      data.append("bathrooms", bathrooms);
      data.append("pricing", values?.price);
      dates.forEach((val) => {
        data.append("availableDates", val);
      });
      checkedArray.forEach((val) => {
        const foundAmenity = amenities.find((amenity) => {
          return amenity.title === val;
        });
        if (foundAmenity) {
          data.append("amenities", foundAmenity._id);
        }
      });
      if (fileList.length > 1) {
        const fileListToAppend = fileList.slice(1).map((file) => {
          return file.originFileObj;
        });
        fileListToAppend.forEach((val) => {
          data.append("gallery", val);
        });
      }
      if (fileList[0]) {
        data.append("image", fileList[0].originFileObj);
      }
      Post(STAY.addRoom, data, token, null, "multipart")
        .then((response) => {
          if (response?.status) {
            form.resetFields();
            swal("System Alert!", response?.message, "success");
            navigate("/roomListing");
          }
        })
        .catch((err) => {
          console.log(err);
          const message = err?.response?.data?.message;
          if (message) {
            swal("Error!", message, "error");
          }
        });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleUploadChange = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const getProfileAmenities = () => {
    Get(AMENITY.getMyProfileAmenities, token, null)
      .then((response) => {
        setAmenities(response?.data?.amenities);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileAmenities();
  }, []);

  const onAmenityChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setCheckedArray(checkedValues);
  };
  const amenityIcons = {
    "Breakfast": <ImSpoonKnife />,
    "Air Conditioning/Heating": <FaSnowflake />,
    "Free Wi-Fi": <FaWifi />,
    "Parking": <FaParking />,
    "Airport Shuttle": <MdAirportShuttle />,
    "Swimming Pool": <FaSwimmingPool />,
    "Fitness Center": <MdFitnessCenter />,
    "Spa and Wellness": <FaSpa />,
    "Restaurant": <MdOutlineRestaurantMenu />,
    "24-Hour Front Desk": <Tb24Hours />,
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const addMoreDate = () => {
    setDates([...dates, null]);
  };
  const handleChangeDate = (date, index) => {
    const updatedDates = [...dates];
    updatedDates[index] = moment(date?.$d).format("YYYY-MM-DD");
    setDates(updatedDates);
  };

  const handleMinusClick = (type) => {
    switch (type) {
      case "guests":
        setGuests(Math.max(guests - 1, 1));
        break;
      case "beds":
        setBeds(Math.max(beds - 1, 1));
        break;
      case "bathrooms":
        setBathrooms(Math.max(bathrooms - 1, 1));
        break;

      default:
        break;
    }
  };

  const handlePlusClick = (type) => {
    switch (type) {
      case "guests":
        setGuests(guests + 1);
        break;
      case "beds":
        setBeds(beds + 1);
        break;
      case "bathrooms":
        setBathrooms(bathrooms + 1);
        break;

      default:
        break;
    }
  };
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-parent dashboard-right-card">
                        <Row
                          style={{ width: "100%", justifyContent: "center" }}
                        >
                          <Col lg={24}>
                            <div className="arrow-box">
                              <FaArrowLeftLong
                                className="arrow"
                                onClick={() => navigate(-1)}
                              />
                              <h3 className="heading-28">
                                Add Stay Room For Booking
                              </h3>
                            </div>
                          </Col>
                        </Row>

                        <Row
                          style={{ width: "100%", justifyContent: "center" }}
                        >
                          <Col lg={24}>
                            <Row justify="center">
                              <Col xl={24} lg={20}>
                                <Form
                                  className="row g-3"
                                  name="basic"
                                  layout="vertical"
                                  initialValues={{
                                    remember: true,
                                  }}
                                  onFinish={onFinish}
                                  onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                  form={form}
                                >
                                  <Row
                                    style={{ width: "100%" }}
                                    gutter={[16, 16]}
                                  >
                                    <Col lg={12} md={12} xs={24}>
                                      <Row
                                        style={{ width: "100%" }}
                                        gutter={[16, 16]}
                                      >
                                        <Col lg={24} md={24} xs={24}>
                                          <Form.Item
                                            valuePropName="fileList"
                                            getValueFromEvent={normFile}
                                          >
                                            <Upload
                                              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                              listType="picture-card"
                                              fileList={fileList}
                                              onChange={handleUploadChange}
                                            >
                                              {fileList.length >= 8
                                                ? null
                                                : uploadButton}
                                            </Upload>
                                          </Form.Item>
                                        </Col>
                                        <Col lg={24} md={24} xs={24}>
                                          <h5 className="heading-18">
                                            How Many Guest Would You Like To
                                            Welcome
                                          </h5>
                                          <Menu>
                                            <Menu.Item>
                                              <Space>
                                                <span>Guests</span>
                                                <Button
                                                  shape="circle"
                                                  icon={<MinusOutlined />}
                                                  onClick={() =>
                                                    handleMinusClick("guests")
                                                  }
                                                />
                                                <Input
                                                  type="number"
                                                  min={1}
                                                  value={guests}
                                                />
                                                <Button
                                                  shape="circle"
                                                  icon={<PlusOutlined />}
                                                  onClick={() =>
                                                    handlePlusClick("guests")
                                                  }
                                                />
                                              </Space>
                                            </Menu.Item>
                                            <Menu.Item>
                                              <Space>
                                                <span>Beds</span>
                                                <Button
                                                  shape="circle"
                                                  icon={<MinusOutlined />}
                                                  onClick={() =>
                                                    handleMinusClick("beds")
                                                  }
                                                />
                                                <Input
                                                  type="number"
                                                  min={1}
                                                  value={beds}
                                                />
                                                <Button
                                                  shape="circle"
                                                  icon={<PlusOutlined />}
                                                  onClick={() =>
                                                    handlePlusClick("beds")
                                                  }
                                                />
                                              </Space>
                                            </Menu.Item>
                                            <Menu.Item>
                                              <Space>
                                                <span>Bathrooms</span>
                                                <Button
                                                  shape="circle"
                                                  icon={<MinusOutlined />}
                                                  onClick={() =>
                                                    handleMinusClick(
                                                      "bathrooms"
                                                    )
                                                  }
                                                />
                                                <Input
                                                  type="number"
                                                  min={1}
                                                  value={bathrooms}
                                                />
                                                <Button
                                                  shape="circle"
                                                  icon={<PlusOutlined />}
                                                  onClick={() =>
                                                    handlePlusClick("bathrooms")
                                                  }
                                                />
                                              </Space>
                                            </Menu.Item>
                                          </Menu>
                                        </Col>
                                        <Col lg={24} md={24} xs={24}>
                                          <h5 className="heading-18">
                                            Are these bathroom(s) private?
                                          </h5>
                                          <Radio.Group
                                            onChange={onChange}
                                            value={value}
                                          >
                                            <Radio value={1}>Yes</Radio>
                                            <Radio value={2}>No</Radio>
                                          </Radio.Group>
                                        </Col>
                                        <Col lg={24} md={24} xs={24}>
                                          <h5 className="heading-18">
                                            Pricing
                                          </h5>

                                          <Form.Item
                                            // label="Create a Title"
                                            name="price"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Please enter Price!",
                                              },
                                            ]}
                                          >
                                            <Input
                                              size="large"
                                              type="number"
                                              placeholder="Enter Pricing here"
                                              className="web-input"
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Col>

                                    <Col lg={12} md={12} xs={24}>
                                      <Row
                                        style={{ width: "100%" }}
                                        gutter={[16, 16]}
                                      >
                                        <Col lg={24} md={24} xs={24}>
                                          <Form.Item
                                            label="Create a Title"
                                            name="title"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Please enter Title!",
                                              },
                                            ]}
                                          >
                                            <Input
                                              size="large"
                                              placeholder="Luxury Bedroom Suite"
                                              className="web-input"
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col lg={24} md={24} xs={24}>
                                          <Form.Item
                                            label="Create your Description"
                                            name="description"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please enter your Description!",
                                              },
                                            ]}
                                          >
                                            <TextArea
                                              rows={10}
                                              placeholder="Bring the whole family to this great place with lots of room for fun."
                                              className="web-textarea"
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row gutter={[16, 16]}>
                                    <Col lg={24}>
                                      <h5 className="heading-18">
                                        ADD AMENITIES
                                      </h5>
                                      {Array.isArray(amenities) && (
                                        <div className="offer-tabs">
                                          <Checkbox.Group
                                            options={
                                              Array.isArray(amenities) &&
                                              amenities.map((option) => ({
                                                label: (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                    className="check-icon"
                                                  >
                                                    {
                                                      amenityIcons[
                                                        option?.title
                                                      ]
                                                    }
                                                    {option?.title}
                                                  </div>
                                                ),
                                                value: option?.title,
                                                style: {
                                                  backgroundColor:
                                                    checkedArray.includes(
                                                      option?.title
                                                    )
                                                      ? "#0f75bd"
                                                      : "",
                                                },
                                              }))
                                            }
                                            onChange={onAmenityChange}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                  <h3 className="booking-card-name">
                                    Set Your Availability Dates
                                  </h3>
                                  <Row
                                    style={{ width: "100%" }}
                                    gutter={[16, 16]}
                                  >
                                    <Col lg={8} md={12} xs={24}>
                                      {dates.map((date, index) => (
                                        <div key={index}>
                                          <DatePicker
                                            className="web-input"
                                            onChange={(date) =>
                                              handleChangeDate(date, index)
                                            }
                                            disabledDate={disabledDate}
                                          />
                                        </div>
                                      ))}
                                      <Button
                                        className="web-btn2"
                                        type="link"
                                        onClick={addMoreDate}
                                      >
                                        Add More Dates
                                      </Button>
                                    </Col>
                                  </Row>
                                  <div style={{ margin: "20px 0" }}>
                                    <Button
                                      type=""
                                      className="web-btn2"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                      onClick={() => {
                                        navigate("/roomListing");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      type="submit"
                                      htmlType="submit"
                                      className="web-btn"
                                    >
                                      Publish Room
                                    </Button>
                                  </div>
                                </Form>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddDropZone;
