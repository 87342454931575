import { useEffect, useRef, useState } from "react";
import {
  Col,
  Row, Button,
  Typography, Spin
} from "antd";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import DashbordSidebar from "../../components/DashboardSidebar";
import { GiCampingTent, GiBoxUnpacking } from "react-icons/gi";
import { IoRestaurantSharp } from "react-icons/io5";
import {
  MdCarRental,
  MdElectricBolt,
  MdBalcony,
  MdOutlineKingBed
} from "react-icons/md";
import { SlOrganization } from "react-icons/sl";
import { GiHook } from "react-icons/gi";
import { FaSwimmingPool, FaShower } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  FaArrowLeftLong,
  FaBed,
  FaPersonSwimming,
  FaParachuteBox,
  FaVideo,
  FaLocationDot,
} from "react-icons/fa6";
import Slider from "react-slick";
import { Get } from "../../config/api/get";
import { Delete } from "../../config/api/delete";
import { REVIEWS, STAY, UPLOADS_URL } from "../../config/constants/api";
import swal from "sweetalert";

const Dashboard = () => {
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [gigReviews, setGigReviews] = useState(null);
  const [roomDetails, setRoomDetails] = useState(null);
  const [profile, setProfile] = useState(null);

  const { id } = useParams();
  const [allImages, setAllImages] = useState(null);
  const getRoomDetails = () => {
    setLoading(true);
    Get(`${STAY.getRoom}${id}`, token)
      .then((response) => {
        setRoomDetails(response?.data?.room);
        setProfile(response?.data?.profile);
        setAllImages([
          response?.data?.room?.image,
          ...response?.data?.room?.gallery,
        ]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error Fetching Room Details", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getRoomDetails();
  }, []);
  const getReviews = () => {
    Get(`${REVIEWS.getGigReviews}${id}`, token)
      .then((response) => {
        setGigReviews(response?.data?.docs);
      })
      .catch((err) => {
        console.log("Error Fetching Reviews", err);
      });
  };
  const handleDelete = () => {
    Delete(`${STAY.deleteRoom}${roomDetails?._id}`, token)
      .then((response) => {
        if (response.status) {
          swal("Success", "Room Deleted Successfully", "success");
          navigate("/roomListing");
        }
      })
      .catch((err) => {
        console.log("Error", err);
        swal("Error", err?.response?.data?.message, "error");
      });
  };
  const getRank = (rating) => {
    const ratingCounts = gigReviews?.reduce((acc, review) => {
      acc[review.rating] = (acc[review.rating] || 0) + 1;
      return acc;
    }, {});
    const sortedRatings = Object.keys(ratingCounts).sort(
      (a, b) => ratingCounts[b] - ratingCounts[a]
    );
    let rank = 1;
    let prevCount = null;
    for (let i = 0; i < sortedRatings.length; i++) {
      const currentRating = parseInt(sortedRatings[i]);
      const currentCount = ratingCounts[currentRating];

      if (currentCount !== prevCount) {
        rank = i + 1;
        prevCount = currentCount;
      }
      if (currentRating === rating) {
        return rank;
      }
    }
    return 0;
  };

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, [slider1?.current, slider2?.current, allImages]);

  const sliderSettings = {
    arrows: false,
    // other settings...
  };
  const sliderSettings2 = {
    // arrows: false,
    // style: {
    //   margin: "20px",
    // },
  };

  const { Title } = Typography;

  const amenityIcons = {
    "Bunkhouse with Beds": <FaBed />,
    "Designated Camping Area": <GiCampingTent />,
    "Equipment Rental/Sales": <MdCarRental />,
    "Full-Service Restaurant": <IoRestaurantSharp />,
    "Load Organizers": <SlOrganization />,
    "Packing Service": <GiBoxUnpacking />,
    "Rigging Service": <GiHook />,
    "RV Space w/ Electricity": <MdElectricBolt />,
    "Showers Facilities Swimming": <FaPersonSwimming />,
    Pool: <FaSwimmingPool />,
    "Swoop Pond": <FaParachuteBox />,
    "Team Rooms Videographers": <FaVideo />,
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    {!loading && roomDetails ? (
                      <div className="about-us-section">
                        <div className="bg-parent dashboard-right-card">
                          <Row
                            style={{ width: "100%", justifyContent: "center" }}
                          >
                            <Col lg={24}>
                              <div className="arrow-box">
                                <FaArrowLeftLong
                                  className="arrow"
                                  onClick={() => navigate(-1)}
                                />
                                <h3 className="heading-28">
                                  Stay Room For Booking Details
                                </h3>
                              </div>
                              <div className="delete-and-edit">
                                <Button
                                  type="danger"
                                  className="delete-btn"
                                  onClick={handleDelete}
                                >
                                  <RiDeleteBin6Line />
                                </Button>
                              </div>
                            </Col>
                          </Row>

                          <Row justify="center">
                            <Col lg={23}>
                              <Row align="" gutter={16}>
                                <Col lg={10}>
                                  <div
                                    className="one-product"
                                    style={{ paddingRight: "30px" }}
                                  >
                                    <div className="search-img">
                                      <div>
                                        <Slider
                                          asNavFor={nav2}
                                          ref={slider1}
                                          {...sliderSettings}
                                        >
                                          {Array.isArray(allImages) &&
                                            allImages.map((image, index) => {
                                              return (
                                                <div key={index}>
                                                  <span>
                                                    <img
                                                      src={UPLOADS_URL + image}
                                                      alt=""
                                                      className="img-fluid"
                                                      style={{
                                                        width: "100%",
                                                        maxHeight: "300px",
                                                        objectFit: "cover",
                                                        objectPosition:
                                                          "center",
                                                      }}
                                                    />
                                                  </span>
                                                </div>
                                              );
                                            })}
                                        </Slider>
                                        <Slider
                                          asNavFor={nav1}
                                          ref={slider2}
                                          slidesToShow={
                                            roomDetails?.gallery?.length
                                          }
                                          swipeToSlide={true}
                                          focusOnSelect={true}
                                          {...sliderSettings2}
                                        >
                                          {Array.isArray(allImages) &&
                                            allImages.map((image, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="slider-nav"
                                                >
                                                  <span>
                                                    <img
                                                      src={UPLOADS_URL + image}
                                                      alt=""
                                                      className="img-fluid"
                                                      style={{
                                                        width: "100%",
                                                        height: "90px",
                                                        objectFit: "cover",
                                                        objectPosition:
                                                          "center",
                                                      }}
                                                    />
                                                  </span>
                                                </div>
                                              );
                                            })}
                                        </Slider>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={14}>
                                  <div className="room-details">
                                    <div>
                                      <h4 className="text-26">
                                        {roomDetails?.title}
                                      </h4>
                                      <p className="icon-span">
                                        <FaLocationDot />{" "}
                                        {profile?.location?.address +
                                          " " +
                                          profile?.location?.street +
                                          " " +
                                          profile?.location?.state}
                                      </p>
                                    </div>
                                    <div className="search-result-detail-btnbox">
                                      <h6 className="per-day-price">
                                        ${roomDetails?.pricing + ".00"}
                                        <span>/Per day</span>
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="search-img-box-right additional-details">
                                    <h5 className="heading-18">
                                      Additional Details
                                    </h5>
                                    <p>
                                      <MdBalcony />
                                      {roomDetails?.description}
                                    </p>
                                    <p>
                                      <FaShower />
                                      {roomDetails?.bathrooms + " Bathrooms"}
                                    </p>
                                    <p>
                                      <MdOutlineKingBed />{" "}
                                      {roomDetails?.beds + " Beds"}
                                    </p>
                                  </div>
                                  <div
                                    className="search-img-box-right"
                                    style={{ paddingBottom: "20px" }}
                                  >
                                    <h5 className="heading-18 py-2">
                                      Extra Charges
                                    </h5>
                                    {/* <Checkbox
                                      id="inlineCheckbox2"
                                      className="form-check-input"
                                      value="option2"
                                    > */}
                                    <p className="web-p">
                                      
                                      $20 extra charges for room cleaning
                                    </p>
                                    {/* </Checkbox> */}
                                  </div>
                                  <div>
                                    <Button 
                                      type=""
                                      className="web-btn"
                                      onClick={() => navigate("/editRoom", {state :{roomDetails}})}
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>  
                      </div>
                    ) : (
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "150px 550px",
                        }}
                      >
                        <Spin />
                      </Col>
                    )}
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
