import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AdminAuthCheck from "../../components/AuthCheck/AdminAuthCheck";
import ClientLayout from "../../components/ClientLayout";
import CreateAccount from "../../views/createAccount";
import Login from "../../views/login";
import ForgetPassword1 from "../../views/forget-password-1";
import ForgetPassword2 from "../../views/forget-password-2";
import ForgetPassword3 from "../../views/forget-password-3";
import AboutUs from "../../views/aboutUs";
import PrivacyPolicy from "../../views/privacyPolicy";
import TermsConditions from "../../views/termsConditions";
import ProtectedRoute from "../../views/protectedRoute";
import Profile from "../../views/profile";
import EditProfile from "../../views/profile/editProfile";
import ChangePassword from "../../views/profile/changePassword";
import Help from "../../views/help";
import CreateStayProfile from "../../views/createStayProfile";
import StayProfileView from "../../views/stayProfileView";
import StayProfileViewEdit from "../../views/stayProfileView/stayProfileViewEdit";
import Dashboard from "../../views/dashboard";
import Notifications from "../../views/notifications/";
import ReservationLogs from "../../views/reservationLogs";
import ReservationLogsDetails from "../../views/reservationLogs/reservationLogsDetails";
import RoomListing from "../../views/roomListing";
import AddRoom from "../../views/roomListing/addRoom";
import EditRoom from "../../views/roomListing/editRoom";
import RoomDetails from "../../views/roomListing/roomDetails";
// import Notifications from "../../views/notifications";

const MyRouter = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/createAccount"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CreateAccount />
            </ClientLayout>
          }
        />
        <Route
          path="/login"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Login />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-1"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword1 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-2"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword2 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-3"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword3 />
            </ClientLayout>
          }
        />
        <Route
          path="/aboutUs"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <AboutUs />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/privacyPolicy"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <PrivacyPolicy />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/termsConditions"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <TermsConditions />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Profile />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/editProfile"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <EditProfile />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/changePassword"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ChangePassword />
              </ClientLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/help"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Help />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/createStayProfile"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <CreateStayProfile />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/stayProfileView"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <StayProfileView />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/stayProfileViewEdit"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <StayProfileViewEdit />
              </ClientLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Dashboard />
              </ClientLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/notifications"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Notifications />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reservationLogs"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ReservationLogs />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reservationLogs/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ReservationLogsDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/roomListing"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <RoomListing />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/addRoom"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <AddRoom />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/editRoom"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <EditRoom />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/roomDetails/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <RoomDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default MyRouter;
