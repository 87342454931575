import React from "react";
import {
  Layout,
  Col,
  Row,
  Button, Form,
  Input,
  Select, Image
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { COMPANY, STAY } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import fbicn from "../../assets/facebook-icon.png";
import gogoleicn from "../../assets/google-icon.png";

import swal from "sweetalert";

function CreateAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);
  const onFinish = (values) => {
    setLoading(true);
    let data = {
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      mobile: values.mobile,
      gender: values?.gender,
      deviceId: "123456789",
    };
    Post(STAY.signup, data)
      .then((response) => {
        setLoading(false);
        dispatch(
          addUser({ user: response.data?.user, token: response.data?.token })
        );
        swal("Success!", response.message, "success");
        navigate("/");
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        setLoading(false);
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

 

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="auth-banner">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={18} lg={15}>
            <div className="auth-box">
              <div className="blur-bg-inner-card-form">
                <h2 className="auth-heading">Sign Up</h2>
                <p className="auth-p">Fill Out This Form To Sign Up</p>
                <Form
                  className="row g-3"
                  name="basic"
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row
                    style={{ width: "100%", justifyContent: "center" }}
                    gutter={[16, 16]}
                  >
                    <Col lg={12}>
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter First Name"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Enter Last Name",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Email Address"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                          {
                            required: true,
                            message: "Please enter your email address!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="James.Anderson@gmail.com"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label="Phone Number"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your phone number!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Phone Number"
                          className="web-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={24}>
                      <Form.Item
                        label="Select Gender"
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select gender!",
                          },
                        ]}
                      >
                        <Select placeholder="Select">
                          <Select.Option value="MALE">Male</Select.Option>
                          <Select.Option value="FEMALE">Female</Select.Option>
                          <Select.Option value="GENDER">Gender</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  
                    <Col lg={12}>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Enter Password",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Enter Password"
                          className="web-input"
                          style={{
                            borderRadius: "5px",
                            fontSize: "14px",
                            paddingRight: "10px",
                            backgroundColor: "transparent",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("The two passwords do not match!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Confirm Password"
                          className="web-input"
                          style={{
                            borderRadius: "5px",
                            fontSize: "14px",
                            paddingRight: "10px",
                            backgroundColor: "transparent",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={24} className="signup-with-buttons">
                      <div className="g-btn">
                        <a href="#_" className="signup-with-btn">
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={fbicn}
                            className=""
                          />
                          Signup with Facebook
                        </a>
                        <a href="#_" className="signup-with-btn">
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={gogoleicn}
                            className=""
                          />
                          Signup with Google
                        </a>
                      </div>
                    </Col>
                    <div className="" style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        htmlType="submit"
                        className="btn web-btn px-5"
                      >
                        Sign Up
                      </Button>
                      <div
                        className="already-account-text"
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        Already Have An Account?{" "}
                        <span onClick={() => navigate("/login")}>Login</span>{" "}
                      </div>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default CreateAccount;
