import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Upload,
  Button,
  Image,
  Checkbox,
} from "antd";
import { useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import swal from "sweetalert";
import paymentImg from "../../assets/payment-img.png";
import { useDispatch, useSelector } from "react-redux";
import { AMENITY, STAY, UPLOADS_URL } from "../../config/constants/api";
import { Put } from "../../config/api/put";
import {
  MdAirportShuttle,
  MdFitnessCenter,
  MdOutlineRestaurantMenu,
} from "react-icons/md";
import {
  FaSnowflake,
  FaWifi,
  FaParking,
  FaSwimmingPool,
  FaSpa,
} from "react-icons/fa";
import { Tb24Hours } from "react-icons/tb";
import { ImSpoonKnife } from "react-icons/im";
import { Get } from "../../config/api/get";
import { addProfileDetails } from "../../redux/slice/authSlice";

const normFile = (e) => {
  if (Array.isArray(e)) {
    console.log(e);
    return e;
  }
  return e?.fileList;
};

const DropzoneCompany = () => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);
  const [initialProfileDetails, setInitialProfileDetails] = useState(null);
  const [oldImages, setOldImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [checkedArray, setCheckedArray] = useState([]);
  const [initialChecked, setInitialChecked] = useState([]);
  const [stayAmenities, setStayAmenities] = useState(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const profileDetails = useSelector((state) => state.user.profileDetails);

  const getStayAmenities = () => {
    Get(AMENITY.getAmenities, authToken, { type: "STAY" })
      .then((response) => {
        if (response.status) {
          setStayAmenities(response?.data?.docs);
        }
      })
      .catch((err) => {
        console.log(err, " Error fetching stay Amenities");
      });
  };
  useEffect(() => {
    getStayAmenities();
  }, []);
  useEffect(() => {
    if (!profileDetails) {
      navigate("/createStayProfile");
    }
    setInitialProfileDetails(profileDetails);
    const combinedImages = [
      ...(profileDetails?.image ? [UPLOADS_URL + profileDetails.image] : []),
      ...(profileDetails?.gallery || []).map(
        (filename) => UPLOADS_URL + filename
      ),
    ];
    setFileList(
      combinedImages.map((url) => ({ uid: url, name: url, url: url }))
    );
    setExistingImages(
      combinedImages.map((url) => ({ uid: url, name: url, url: url }))
    );

    let names = profileDetails?.amenities?.map((amenity) => amenity.title);
    setCheckedArray(names);
    setInitialChecked(names);
    form.setFieldsValue({
      address: profileDetails?.location?.address,
      street: profileDetails?.location?.street,
      city: profileDetails?.location?.city,
      state: profileDetails?.location?.state,
      country: profileDetails?.location?.country,
      zipCode: profileDetails?.location?.zipCode,
      apartment: profileDetails?.location?.apartment
        ? profileDetails?.location?.apartment
        : "",
      title: profileDetails?.title,
      description: profileDetails?.description,
      accountName: profileDetails?.accountDetails?.name,
      bankAddress: profileDetails?.accountDetails?.bankAddress,
      branchName: profileDetails?.accountDetails?.branchName,
      iban: profileDetails?.accountDetails?.iban,
      swift: profileDetails?.accountDetails?.swift,
      bankName: profileDetails?.accountDetails?.bankName,
      accNumber: profileDetails?.accountDetails?.accNumber,
      accountCurrency: profileDetails?.accountDetails?.currency,
      accountCity: profileDetails?.accountDetails?.city,
      accountCountry: profileDetails?.accountDetails?.country,
    });
  }, []);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  const onFinish = (values) => {
    const location = {
      address: values.address,
      apartment: values.apartment,
      street: values.street,
      state: values.state,
      city: values.city,
      country: values.country,
      zipCode: values.zipCode,
    };
    const accountDetails = {
      name: values.accountName,
      country: values.accountCountry,
      city: values.accountCity,
      iban: values.iban,
      accNumber: values.accNumber,
      swift: values.swift,
      bankName: values.bankName,
      branchName: values.branchName,
      bankAddress: values.bankAddress,
      currency: values.accountCurrency,
    };
    const formValuesChanged = () => {
      if (!initialProfileDetails) {
        return true;
      }
      return (
        values.address !== initialProfileDetails?.location?.address ||
        values.street !== initialProfileDetails?.location?.street ||
        values.city !== initialProfileDetails?.location?.city ||
        values.state !== initialProfileDetails?.location?.state ||
        values.country !== initialProfileDetails?.location?.country ||
        values.zipCode !== initialProfileDetails?.location?.zipCode ||
        values.apartment !==
          (initialProfileDetails?.location?.apartment
            ? initialProfileDetails?.location?.apartment
            : "") ||
        values.briefUrl !== initialProfileDetails?.briefUrl ||
        values.briefTitle !== initialProfileDetails?.briefTitle ||
        values.briefDescription !== initialProfileDetails?.briefDescription ||
        values.title !== initialProfileDetails?.title ||
        values.description !== initialProfileDetails?.description ||
        values.accountName !== initialProfileDetails?.accountDetails?.name ||
        values.bankAddress !==
          initialProfileDetails?.accountDetails?.bankAddress ||
        values.branchName !==
          initialProfileDetails?.accountDetails?.branchName ||
        values.iban !== initialProfileDetails?.accountDetails?.iban ||
        values.swift !== initialProfileDetails?.accountDetails?.swift ||
        values.bankName !== initialProfileDetails?.accountDetails?.bankName ||
        values.accNumber !== initialProfileDetails?.accountDetails?.accNumber ||
        values.accountCurrency !==
          initialProfileDetails?.accountDetails?.currency ||
        values.accountCity !== initialProfileDetails?.accountDetails?.city ||
        values.accountCountry !==
          initialProfileDetails?.accountDetails?.country ||
        oldImages.length > 0 ||
        newImages.length > 0 ||
        !arraysEqual(checkedArray, initialChecked)
      );
    };
    if (formValuesChanged()) {
      let data = new FormData();
      if (newImages.length > 0) {
        const galleryToAppend = newImages.map((file, index) => {
          return file.originFileObj;
        });
        galleryToAppend.forEach((val) => {
          data.append("gallery", val);
        });
      }
      if (oldImages.length > 0) {
        const oldImagesToAppend = oldImages.map((file, index) => {
          const parts = file.url.split("/");
          const filename = parts[parts.length - 1];
          return filename;
        });
        oldImagesToAppend.forEach((val, index) => {
          data.append(`oldImages[${index}]`, val);
        });
      }
      if (fileList[0]?.originFileObj) {
        data.append("image", fileList[0]?.originFileObj);
      }

      if (profileDetails?.title !== values?.title) {
        data.append("title", values.title);
      }
      if (profileDetails?.description !== values?.description) {
        data.append("description", values.description);
      }
      data.append("location", JSON.stringify(location));
      data.append("accountDetails", JSON.stringify(accountDetails));
      checkedArray.forEach((val) => {
        const foundAmenities = stayAmenities.filter((amenity) => {
          return amenity.title === val;
        });
        foundAmenities.forEach((foundAmenity) => {
          data.append("amenities", foundAmenity._id);
        });
      });
      Put(
        `${STAY.updateProfile}${profileDetails?._id}`,
        authToken,
        data,
        null,
        "multipart"
      )
        .then((response) => {
          if (response.status) {
            const selectedAmenityObjects = checkedArray.map((title) =>
              stayAmenities.find((amenity) => amenity.title === title)
            );

            dispatch(
              addProfileDetails({
                details: {
                  ...response?.data,
                  amenities: selectedAmenityObjects,
                },
              })
            );

            form.resetFields();
            setFileList([]);
            setExistingImages([]);
            setNewImages([]);
            swal("System Alert", "Profile Updated Successfully", "success");
            navigate("/stayProfileView");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          const message = err?.response?.data?.message;
          if (message) {
            swal("Oops", message, "error");
          }
        });
    } else {
      swal("System Alert!", "No changes detected", "error");
      return;
    }
  };
  const { TextArea } = Input;

  const multipleChange = ({ fileList: newFileList }) => {
    const existingImageUIDs = existingImages.map((image) => image.uid);
    const newImageUIDs = newFileList.map((file) => file.uid);
    const removedImages = existingImages.filter(
      (image) => !newImageUIDs.includes(image.uid)
    );
    setOldImages([...removedImages]);
    const updatedFileList = newFileList.filter(
      (file) => !existingImageUIDs.includes(file.uid)
    );
    setNewImages(updatedFileList);
    setFileList(newFileList);
  };

  const amenityOptions = [
    {
      label: "Breakfast",
      value: "Breakfast",
      image: <ImSpoonKnife />,
    },

    {
      label: "Air Conditioning/Heating",
      value: "Air Conditioning/Heating",
      image: <FaSnowflake />,
    },
    {
      label: "Free Wi-Fi",
      value: "Free Wi-Fi",
      image: <FaWifi />,
    },
    {
      label: "Parking",
      value: "Parking",
      image: <FaParking />,
    },
    {
      label: "Airport Shuttle",
      value: "Airport Shuttle",
      image: <MdAirportShuttle />,
    },
    {
      label: "Swimming Pool",
      value: "Swimming Pool",
      image: <FaSwimmingPool />,
    },
    {
      label: "Fitness Center",
      value: "Fitness Center",
      image: <MdFitnessCenter />,
    },
    {
      label: "Spa and Wellness",
      value: "Spa and Wellness",
      image: <FaSpa />,
    },
    {
      label: "Restaurant",
      value: "Restaurant",
      image: <MdOutlineRestaurantMenu />,
    },
    {
      label: "24-Hour Front Desk",
      value: "24-Hour Front Desk",
      image: <Tb24Hours />,
    },
  ];
  const onAmenityChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setCheckedArray(checkedValues);
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">Edit Stay Profile</h3>
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <Row justify="center">
                <Col xl={24} lg={20}>
                  <div className="details-card my-4">
                    <Form
                      className="row g-3"
                      name="basic"
                      layout="vertical"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                      autoComplete="off"
                      form={form}
                    >
                      <Row style={{ width: "100%" }} gutter={[16, 16]}>
                        <Col lg={12} md={12} xs={24}>
                          <Row style={{ width: "100%" }} gutter={[16, 16]}>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                              >
                                <Upload
                                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                  listType="picture-card"
                                  fileList={fileList}
                                  onChange={multipleChange}
                                >
                                  {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <h3>Enter Location</h3>
                              <Form.Item label="Address" name="address">
                                <Input
                                  size="large"
                                  placeholder="Enter Address"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="Street" name="street">
                                <Input
                                  size="large"
                                  placeholder="Enter Street"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="Apartment" name="apartment">
                                <Input
                                  size="large"
                                  placeholder="Apartment #, Suite, etc. (Optional)"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="City" name="city">
                                <Input
                                  size="large"
                                  placeholder="Select City"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="State" name="state">
                                <Input
                                  size="large"
                                  placeholder="Select State"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="Zip Code" name="zipCode">
                                <Input
                                  size="large"
                                  placeholder="Enter Zip Code"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="Country" name="country">
                                <Input
                                  size="large"
                                  placeholder="Select Country"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={12} md={12} xs={24}>
                          <Row style={{ width: "100%" }} gutter={[16, 16]}>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Stay Title"
                                name="title"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Stay Title!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Stay Title Here"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your Description!",
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Bring the whole family to this great place with lots of room for fun."
                                  maxLength={6}
                                  className="web-textarea"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[16, 16]}>
                            <Col lg={24}>
                              <h5 className="heading-18">ADD AMENITIES</h5>
                              <div className="offer-tabs">
                                <Checkbox.Group
                                  options={amenityOptions.map((option) => ({
                                    label: (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                        className="check-icon"
                                      >
                                        {option.image}
                                        {option.label}
                                      </div>
                                    ),
                                    value: option.value,
                                    style: {
                                      backgroundColor: checkedArray.includes(
                                        option.value
                                      )
                                        ? "#0f75bd"
                                        : "",
                                    },
                                  }))}
                                  value={checkedArray}
                                  onChange={onAmenityChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row style={{ width: "100%" }} gutter={[16, 16]}>
                        <Col lg={10} md={10} xs={24}>
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={paymentImg}
                            className=""
                          />
                        </Col>
                        <Col lg={14} md={14} xs={24}>
                          <h2 className="booking-card-price">
                            Bank Account Details
                          </h2>
                          <Row style={{ width: "100%" }} gutter={[16, 16]}>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Beneficiary Name"
                                name="accountName"
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Beneficiary Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Remittance Country"
                                name="accountCountry"
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Country"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Account Currency"
                                name="accountCurrency"
                              >
                                <Input
                                  size="large"
                                  placeholder="$"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="IBAN" name="iban">
                                <Input
                                  size="large"
                                  placeholder="Enter IBAN"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Account Number"
                                name="accNumber"
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Account Number"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="Bank Name" name="bankName">
                                <Input
                                  size="large"
                                  placeholder="Enter Bank Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="SWIFT Code/Routing Number"
                                name="swift"
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Swift Code/Routing Number"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="Branch Name" name="branchName">
                                <Input
                                  size="large"
                                  placeholder="Enter Branch Name"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item label="City" name="accountCity">
                                <Input
                                  size="large"
                                  placeholder="Enter City"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                              <Form.Item
                                label="Bank Address"
                                name="bankAddress"
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Bank Address"
                                  className="web-input"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} xs={24}>
                              <div className="">
                                <Button
                                  type="submit"
                                  htmlType="submit"
                                  className="web-btn2"
                                  style={{
                                    margin: "10px",
                                  }}
                                  onClick={() =>
                                    navigate("/dropzone-company-form-view")
                                  }
                                >
                                  CANCEL
                                </Button>
                                <Button
                                  type="submit"
                                  htmlType="submit"
                                  className="web-btn"
                                  style={{
                                    margin: "10px",
                                  }}
                                >
                                  UPDATE
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneCompany;
